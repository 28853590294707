const homeMenu = {
  index: 0,
  label: 'menu.my_farm',
  icon: 'house-user',
  path: '/my_farm',
  name: 'my_farm',
  paths: ['/', '/my_farm'],
}

const varieties = {
  index: 2,
  label: 'disease_control.varieties',
  icon: 'leaf',
  path: '/cultivares',
  name: 'varieties',
  paths: ['/cultivares'],
}

// CM: Esse cara vai substituir o "varieties", arrumar index e path
const sowingsPlanning = {
  index: 12,
  label: 'disease_control.sowings_planning',
  icon: 'leaf',
  path: '/sowings_planning',
  name: 'sowings_planning',
  paths: ['/sowings_planning'],
}

// CM: Esse cara vai substituir o "diseaseProgram", arrumar index e path
export const diseasesControl = {
  index: 13,
  label: 'disease_control.diseases',
  icon: 'vial',
  path: '/diseases_control',
  name: 'diseases_control',
  paths: ['/diseases_control'],
}

export const diseaseProgram = {
  index: 3,
  label: 'disease_control.diseases',
  icon: 'vial',
  path: '/programas',
  name: 'programs',
  paths: ['/programas', '/comparacao'],
}

const tempPlanningMenu = {
  index: 1,
  label: 'menu.planejamento',
  icon: 'file-signature',
  name: 'planning',
  type: 'dropdown',
  dropdownTitle: '',
  paths: ['/cultivares', '/programas', '/comparacao'],
  children: [varieties, diseaseProgram, sowingsPlanning, diseasesControl],
}

// CM: Esse cara vai ser apagado
const planningMenu = {
  index: 1,
  label: 'menu.planejamento',
  icon: 'file-signature',
  name: 'planning',
  type: 'dropdown',
  dropdownTitle: '',
  paths: ['/cultivares', '/programas', '/comparacao'],
  children: [varieties, diseaseProgram],
}

const currentPlanningMenu = tempPlanningMenu

const sowingsList = {
  index: 13,
  label: 'menu.sowings_list',
  icon: 'seedling',
  path: '/sowings_list',
  name: 'sowings_list',
  paths: ['/sowings_list'],
}

// CM: Esse cara vai ser apagado
const planting = {
  index: 4,
  label: 'menu.semeaduras',
  icon: 'leaf',
  path: '/sowings',
  name: 'planting',
  paths: ['/sowings', '/execucao'],
}

const catalogFungicide = {
  index: 8,
  label: 'menu.fungicides',
  icon: 'vial',
  path: '/catalog?category=fungicide',
}

const catalogNematicide = {
  index: 9,
  label: 'menu.nematicide',
  icon: 'bacterium',
  path: '/catalog?category=nematicide',
}

const catalogVarieties = {
  index: 10,
  label: 'disease_control.varieties',
  icon: 'seedling',
  path: '/catalog?category=variety',
}

const catalogMenu = {
  index: 5,
  label: 'menu.catalog',
  icon: ['fas', 'list'],
  name: 'catalog',
  type: 'dropdown',
  dropdownTitle: '',
  paths: ['/cultivares', '/programas', '/comparacao'],
  children: [catalogFungicide, catalogVarieties, catalogNematicide],
}

const notes = {
  index: 6,
  label: 'menu.notes',
  icon: 'sticky-note',
  path: '/notes',
  name: 'notes',
  paths: ['/notes'],
  tour: 'notes-navbar',
}

const performance = {
  index: 7,
  label: 'menu.performance',
  icon: 'chart-line',
  path: '/performance',
  name: 'performance',
  paths: ['/performance'],
}

const plotMenu = {
  index: 11,
  label: 'menu.plot',
  icon: 'vector-square',
  name: 'plot',
  type: 'dropdown',
  dropdownTitle: '',
  paths: ['/plot'],
  children: [],
}

export const menuTabsNames = {
  my_farm: homeMenu.name,
  planning: planningMenu.name,
  varieties: varieties.name,
  programs: diseaseProgram.name,
  catalog: catalogMenu.name,
  notes: notes.name,
  performance: performance.name,
  plot: plotMenu.name,
}

export default [
  homeMenu,
  plotMenu,
  currentPlanningMenu,
  sowingsList,
  planting,
  catalogMenu,
  notes,
  performance,
]

import { APIFailureWrapper } from '@/utils/mock'
import {
  accounts,
  jobTitles,
  plans,
  usersData,
} from '@/modules/menu/mock/menuSeeds.js'

let currentUser = usersData[0]

const menuRoutes = [
  {
    method: 'get',
    url: '/menu/contas',
    result: () =>
      APIFailureWrapper({
        content: accounts,
        errorMessage: 'Erro na listagem de contas',
      }),
  },
  {
    method: 'get',
    url: '/menu/dados-usuario',
    result: () => {
      const data = usersData.find((user) => user.id == currentUser.id)
      return APIFailureWrapper({
        content: data,
        errorMessage: 'Erro na listagem de dados do usuário',
      })
    },
  },
  {
    method: 'get',
    url: '/job-titles',
    result: () => {
      return APIFailureWrapper({
        content: jobTitles,
        errorMessage: 'Erro na listagem de títulos de cargos',
      })
    },
  },
  {
    method: 'get',
    url: '/menu/plans',
    result: () => {
      return APIFailureWrapper({
        content: plans,
        errorMessage: 'Erro na listagem de planos',
      })
    },
  },
  {
    method: 'put',
    url: '/menu/alterar-conta',
    result: ({ requestBody }) => {
      const responseObj = JSON.parse(requestBody)
      currentUser = usersData.find((user) => user.account.id == responseObj.id)
      return APIFailureWrapper({
        content: null,
        errorMessage: 'Erro na alteração da conta',
      })
    },
  },
]

export { menuRoutes }

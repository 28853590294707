import { BRAZIL, UNITED_STATES } from '@/utils/countries'

const accounts = {
  contas: {
    1: 'Conta com fazendas',
    2: 'Conta primeiro login (sem fazendas)',
  },
}

const jobTitles = [
  {
    id: 1,
    name: 'farm_administrator',
  },
  {
    id: 2,
    name: 'agricultural_technical_consultancy',
  },
  {
    id: 3,
    name: 'manager',
  },
  {
    id: 4,
    name: 'agricultural_engineering_consultancy',
  },
  {
    id: 5,
    name: 'machine_operator',
  },
  {
    id: 6,
    name: 'operation_supervisor',
  },
  {
    id: 7,
    name: 'dealer',
  },
  {
    id: 8,
    name: 'researcher',
  },
  {
    id: 9,
    name: 'student',
  },
  {
    id: 10,
    name: 'other',
  },
]

const usersData = [
  {
    id: 1,
    email: 'dev_br@digifarmz.com',
    user_profile: {
      id: null,
      name: null,
    },
    name: 'Desenvolvedor BR da DigiFarmz',
    profile_name: null,
    language: 'pt',
    languageAccount: 'pt',
    is_admin: true,
    first_login: 'M',
    is_qa_mode_enabled: false,
    notes_enabled: null,
    academy_url: null,
    job_title: {
      id: jobTitles[9].id,
      name: jobTitles[9].name,
    },
    plan: {
      id: 3,
      name: 'Full',
      logo_uri: {
        web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/full_logo_web.png',
        mobile:
          'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/full_logo_mobile.png',
      },
      upsell_uri: {},
      priority_level: 4,
    },
    used_area: false,
    total_area_account: 20000,
    account: {
      id: Object.keys(accounts.contas)[0],
      name: accounts.contas[Object.keys(accounts.contas)[0]],
      country_id: BRAZIL,
      state_id: 21,
      city_id: 4932,
      account_type_id: 2,
      account_type_name: 'DigiFarmz - Colaboradores',
    },
    logo: null,
    ndvi: true,
  },
  {
    id: 2,
    email: 'dev_usa@digifarmz.com',
    user_profile: {
      id: null,
      name: null,
    },
    name: 'Developer USA of DigiFarmz',
    profile_name: null,
    language: 'en',
    languageAccount: 'en',
    is_admin: true,
    first_login: 'M',
    is_qa_mode_enabled: false,
    notes_enabled: null,
    academy_url: null,
    job_title: {
      id: jobTitles[9].id,
      name: jobTitles[9].name,
    },
    plan: {
      id: 3,
      name: 'Full',
      logo_uri: {
        web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/full_logo_web.png',
        mobile:
          'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/full_logo_mobile.png',
      },
      upsell_uri: {},
      priority_level: 4,
    },
    used_area: false,
    total_area_account: 20000,
    account: {
      id: Object.keys(accounts.contas)[0],
      name: accounts.contas[Object.keys(accounts.contas)[0]],
      country_id: UNITED_STATES,
      state_id: 21,
      city_id: 4932,
      account_type_id: 2,
      account_type_name: 'DigiFarmz - Colaboradores',
    },
    logo: null,
    ndvi: true,
  },
  {
    id: 3,
    email: 'first_login@digifarmz.com',
    user_profile: {
      id: null,
      name: null,
    },
    name: 'Suporte da DigiFarmz',
    profile_name: null,
    language: 'pt',
    languageAccount: 'pt',
    is_admin: false,
    first_login: 'Y',
    is_qa_mode_enabled: false,
    notes_enabled: null,
    academy_url: null,
    job_title: {
      id: jobTitles[9].id,
      name: jobTitles[9].name,
    },
    plan: {
      id: 3,
      name: 'Full',
      logo_uri: {
        web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/full_logo_web.png',
        mobile:
          'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/full_logo_mobile.png',
      },
      upsell_uri: {},
      priority_level: 4,
    },
    used_area: false,
    total_area_account: 100,
    account: {
      id: Object.keys(accounts.contas)[1],
      name: accounts.contas[Object.keys(accounts.contas)[1]],
      country_id: BRAZIL,
      state_id: 21,
      city_id: 4932,
      account_type_id: 2,
      account_type_name: 'DigiFarmz - Colaboradores',
    },
    logo: null,
    ndvi: true,
  },
]

const plans = [
  {
    id: 1,
    name: 'Light',
    logo_uri: {
      web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/light_logo_web.png',
      mobile:
        'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/light_logo_mobile.png',
    },
    upsell_uri: {},
    priority_level: 1,
  },
  {
    id: 2,
    name: 'DigiFarmz',
    logo_uri: {
      web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/digifarmz_logo_web.png',
      mobile:
        'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/digifarmz_logo_mobile.png',
    },
    upsell_uri: {},
    priority_level: 3,
  },
  {
    id: 3,
    name: 'Full',
    logo_uri: {
      web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/full_logo_web.png',
      mobile:
        'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/full_logo_mobile.png',
    },
    upsell_uri: {},
    priority_level: 4,
  },
  {
    id: 4,
    name: 'Pro',
    logo_uri: {
      web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/pro_logo_web.png',
      mobile:
        'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/pro_logo_mobile.png',
    },
    upsell_uri: {},
    priority_level: 2,
  },
]

export { accounts, jobTitles, plans, usersData }
